import { FormSheet } from '@/modules/compensation/types';

export const createFormSheet = (): FormSheet => ({
  compensation_id: '',
  date: '',
  conservatee: {
    surname: '',
    forename: '',
    birthday: '',
    attorney_docket: '',
    level_of_care: 0
  },
  personal_circumstances: {
    has_spouse: false,
    has_dependants: false,
    is_pauper: false,
    is_blind_benefit_recipients: false,
    has_additional_conditions: false,
    has_additional_text: '',
  },
  economic_circumstances: {
    placement_cost: {
      cost: 0,
      additional: '',
    },
    payables: {
      amount: 0,
      additional: ''
    },
    assets: [],
    accounts: [],
    revenues: []
  }
});
