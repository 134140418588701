














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class LevelOfCareSelect extends Vue {
  @Prop({ type: Number }) value?: number;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: String, default: 'select.levelOfCare' }) fieldName!: string;

  get list() {
    return [
      { value: null, text: this.$i18n.t('medical.levelOfCare.0') },
      { value: 1, text: this.$i18n.t('medical.levelOfCare.1') },
      { value: 2, text: this.$i18n.t('medical.levelOfCare.2') },
      { value: 3, text: this.$i18n.t('medical.levelOfCare.3') },
      { value: 4, text: this.$i18n.t('medical.levelOfCare.4') },
      { value: 5, text: this.$i18n.t('medical.levelOfCare.5') }
    ];
  }

  created() {
    this.$emit('input', this.value);
  }
}
