


































































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import uuid from 'uuid/v4';
import {
  CONSERVATOR_UPDATE_FORM_SHEET,
  FETCH_FORM_SHEET,
  FETCH_FORM_SHEET_FINANCES,
  UPDATE_FORM_SHEET
} from '../store';
import {
  Account,
  Asset,
  Compensation,
  FormSheet,
  FormSheetFinances,
  FormSheetResponse,
  Revenue
} from '@/modules/compensation/types';
import { ApiResponse } from '@/components/types';
import { createFormSheet } from '@/modules/compensation/model';
import DatePicker from '@/components/form/DatePicker.vue';
import LevelOfCareSelect from '@/modules/conservatorship/components/LevelOfCareSelect.vue';
import { AuthorizationMixin } from '@/views/mixin';

const Compensation = namespace('compensation');

@Component({
  components: {
    LevelOfCareSelect,
    DatePicker
  }
})
export default class UpdateFormSheetDialog extends AuthorizationMixin {
  @Prop({ type: Object }) compensation!: Compensation | null;

  @Compensation.Action(FETCH_FORM_SHEET_FINANCES) fetchFinances!: ({ conservatorshipId, compensationId, date }: { conservatorshipId: string, compensationId: string, date: string }) => Promise<ApiResponse<FormSheetFinances>>;
  @Compensation.Action(FETCH_FORM_SHEET) fetch!: (compensation: string) => Promise<ApiResponse<FormSheetResponse>>;
  @Compensation.Action(UPDATE_FORM_SHEET) updateFormSheet!: (formSheet: FormSheet) => Promise<ApiResponse>;
  @Compensation.Action(CONSERVATOR_UPDATE_FORM_SHEET) conservatorUpdateFormSheet!: (formSheet: FormSheet) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;
  loading: boolean = false;
  updated: boolean = false;

  value: FormSheet = createFormSheet();
  economicCircumstances: any = null;

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.$emit('update:compensation', null);
      this.value = createFormSheet();
      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
      this.updated = false;
    }, 200);
  }

  @Watch('compensation')
  async watchCompensation(compensation?: Compensation) {
    if (!compensation) return;

    const { error, content } = await this.fetch(compensation.id);

    setTimeout(() => {
      this.dialog = true;
    }, 200);

    if (error || !content) return;

    this.value = {
      ...content,
      economic_circumstances: {
        ...content.economic_circumstances,
        accounts: content.economic_circumstances.accounts.map((account) => ({ id: uuid(), ...account })),
        assets: content.economic_circumstances.assets.map((account) => ({ id: uuid(), ...account })),
        revenues: content.economic_circumstances.revenues.map((account) => ({ id: uuid(), ...account }))
      }
    };
  }

  @Watch('value.date')
  async onDate(date: string) {
    if (!date || !this.compensation) return;

    const { error, content } = await this.fetchFinances({
      compensationId: this.compensation.id,
      conservatorshipId: this.compensation.conservatorshipId,
      date
    });

    if (error || !content) return;

    this.economicCircumstances = {
      payables: {
        amount: parseFloat(content.payables),
        additional: this.value.economic_circumstances.payables.additional || ''
      },
      accounts: [...content.accounts]
        .sort((first, second) => {
          if (first.category === second.category) {
            return first.name.localeCompare(second.name);
          }

          return first.category.localeCompare(second.category);
        }),
      assets: [...content.assets],
      revenues: [...content.revenues]
    };
  }

  addDefaultRevenue(type: string, name: string) {
    const includesIncome = undefined !== this.value.economic_circumstances.revenues
      .find((revenue) => revenue.type === type);

    if (!includesIncome) {
      this.value.economic_circumstances.revenues = [
        { amount: 0, name, type, id: uuid() },
        ...this.value.economic_circumstances.revenues
      ];
    }
  }

  removeRevenue({ id }: Revenue) {
    this.value.economic_circumstances.revenues = [
      ...this.value.economic_circumstances.revenues.filter((revenue) => revenue.id !== id)
    ];
  }

  addRevenue() {
    this.value.economic_circumstances.revenues = [
      ...this.value.economic_circumstances.revenues,
      { name: '', amount: 0, id: uuid(), type: 'earned_income' }
    ];
  }

  removeAccount({ id }: Account) {
    this.value.economic_circumstances.accounts = [
      ...this.value.economic_circumstances.accounts.filter((account) => account.id !== id)
    ];
  }

  addAccount(category: string) {
    this.value.economic_circumstances.accounts = [
      ...this.value.economic_circumstances.accounts,
      { name: '', number: '', balance: 0, id: uuid(), category }
    ];
  }

  removeAsset({ id }: Asset) {
    this.value.economic_circumstances.assets = [
      ...this.value.economic_circumstances.assets.filter((asset) => asset.id !== id)
    ];
  }

  addAsset() {
    this.value.economic_circumstances.assets = [
      ...this.value.economic_circumstances.assets,
      { name: '', balance: 0, id: uuid(), type: 'other_assets' }
    ];
  }

  syncAccount({ id, number, name }: Account) {
    const index = this.value.economic_circumstances.accounts.findIndex((asset) => asset.id === id);

    if (index === -1) return;

    const origin = this.economicCircumstances.accounts.find((item: Account) => item.number === number && item.name === name);

    if (!origin) return;

    this.value.economic_circumstances.accounts[index].balance = origin.balance;
  }

  save() {
    this.error = null;

    this.value.economic_circumstances.revenues = this.value.economic_circumstances.revenues
      .filter((revenue) => revenue.amount !== 0);

    if (this.isConservator() && !this.isIndependentConservator()) {
      return this.conservatorUpdateFormSheet(this.value);
    }

    return this.updateFormSheet(this.value);
  }
}
