


























































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import uuid from 'uuid/v4';
import {
  ADD_FORM_SHEET,
  CONSERVATOR_ADD_FORM_SHEET,
  FETCH_FORM_SHEET_CONSERVATEE,
  FETCH_FORM_SHEET_FINANCES,
  FETCH_FORM_SHEET_LAST
} from '../store';
import {
  Account,
  Asset,
  Compensation,
  FormSheet,
  FormSheetConservatee,
  FormSheetFinances,
  Revenue
} from '@/modules/compensation/types';
import { ApiResponse } from '@/components/types';
import { createFormSheet } from '@/modules/compensation/model';
import DatePicker from '@/components/form/DatePicker.vue';
import LevelOfCareSelect from '@/modules/conservatorship/components/LevelOfCareSelect.vue';
import { AuthorizationMixin } from '@/views/mixin';

const Compensation = namespace('compensation');

@Component({
  components: {
    LevelOfCareSelect,
    DatePicker
  }
})
export default class AddFormSheetDialog extends AuthorizationMixin {
  @Prop({ type: Object }) compensation!: Compensation | null;

  @Compensation.Action(FETCH_FORM_SHEET_CONSERVATEE) fetchConservatee!: ({ conservatorshipId, compensationId }: { conservatorshipId: string, compensationId: string }) => Promise<ApiResponse<FormSheetConservatee>>;
  @Compensation.Action(FETCH_FORM_SHEET_FINANCES) fetchFinances!: ({ conservatorshipId, compensationId, date }: { conservatorshipId: string, compensationId: string, date: string }) => Promise<ApiResponse<FormSheetFinances>>;
  @Compensation.Action(FETCH_FORM_SHEET_LAST) fetchLast!: ({ conservatorshipId }: { conservatorshipId: string }) => Promise<ApiResponse<FormSheet>>;

  @Compensation.Action(ADD_FORM_SHEET) addFormSheet!: (formSheet: FormSheet) => Promise<ApiResponse>;
  @Compensation.Action(CONSERVATOR_ADD_FORM_SHEET) conservatorAddFormSheet!: (formSheet: FormSheet) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;
  loading: boolean = false;
  updated: boolean = false;

  value: FormSheet = createFormSheet();

  close(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.$emit('update:compensation', null);
      this.value = createFormSheet();
      this.error = null;
      this.updated = false;

      observer && observer.reset();
    }, 200);
  }

  async setConservatee(compensation: Compensation) {
    const { error, content } = await this.fetchConservatee({
      conservatorshipId: compensation.conservatorshipId,
      compensationId: compensation.id
    });

    if (error || !content) return null;

    this.value.conservatee = {
      birthday: content.birthday,
      surname: content.surname,
      forename: content.forename,
      attorney_docket: content.attorneyDocket,
      level_of_care: content.levelOfCare
    };
  }

  async setLast(compensation: Compensation) {
    const { error, content } = await this.fetchLast({
      conservatorshipId: compensation.conservatorshipId
    });

    if (error || !content) return null;

    this.value.personal_circumstances = { ...content.personal_circumstances };
    this.value.economic_circumstances.payables.additional = content.economic_circumstances.payables.additional;
    this.value.economic_circumstances.placement_cost = { ...content.economic_circumstances.placement_cost };
    this.value.economic_circumstances.revenues = [...content.economic_circumstances.revenues];
  }

  @Watch('compensation')
  watchCompensation(compensation?: Compensation) {
    if (!compensation) return;

    this.value.compensation_id = compensation.id;
    this.value.date = format(Date.now(), 'yyyy-MM-dd');

    setTimeout(() => {
      this.dialog = true;
    }, 200);

    this.setConservatee(compensation)
      .catch((error) => console.log(error));

    this.setLast(compensation)
      .catch((error) => console.log(error));
  }

  @Watch('value.date')
  async onDate(date: string) {
    if (!date || !this.compensation) return;

    const { error, content } = await this.fetchFinances({
      compensationId: this.compensation.id,
      conservatorshipId: this.compensation.conservatorshipId,
      date
    });

    if (error || !content) return;

    this.value.economic_circumstances = {
      ...this.value.economic_circumstances,
      payables: {
        amount: parseFloat(content.payables),
        additional: this.value.economic_circumstances.payables.additional || ''
      },
      accounts: [...content.accounts]
        .sort((first, second) => {
          if (first.category === second.category) {
            return first.name.localeCompare(second.name);
          }

          return first.category.localeCompare(second.category);
        }),
      assets: [...content.assets],
      revenues: [...this.value.economic_circumstances.revenues, ...content.revenues]
    };

    this.addDefaultRevenue('earned_income', 'Gehalt');
  }

  addDefaultRevenue(type: string, name: string) {
    if (this.value.economic_circumstances.revenues.length > 0) return;

    const includesIncome = undefined !== this.value.economic_circumstances.revenues
      .find((revenue) => revenue.type === type);

    if (!includesIncome) {
      this.value.economic_circumstances.revenues = [
        { amount: 0, name, type, id: uuid() },
        ...this.value.economic_circumstances.revenues
      ];
    }
  }

  removeRevenue({ id }: Revenue) {
    this.value.economic_circumstances.revenues = [
      ...this.value.economic_circumstances.revenues.filter((revenue) => revenue.id !== id)
    ];
  }

  addRevenue() {
    this.value.economic_circumstances.revenues = [
      ...this.value.economic_circumstances.revenues,
      { name: '', amount: 0, id: uuid(), type: 'earned_income' }
    ];
  }

  removeAccount({ id }: Account) {
    this.value.economic_circumstances.accounts = [
      ...this.value.economic_circumstances.accounts.filter((account) => account.id !== id)
    ];
  }

  addAccount(category: string) {
    this.value.economic_circumstances.accounts = [
      ...this.value.economic_circumstances.accounts,
      { name: '', number: '', balance: 0, id: uuid(), category }
    ];
  }

  removeAsset({ id }: Asset) {
    this.value.economic_circumstances.assets = [
      ...this.value.economic_circumstances.assets.filter((asset) => asset.id !== id)
    ];
  }

  addAsset() {
    this.value.economic_circumstances.assets = [
      ...this.value.economic_circumstances.assets,
      { name: '', balance: 0, id: uuid(), type: 'other_assets' }
    ];
  }

  save() {
    this.error = null;

    this.value.economic_circumstances.revenues = this.value.economic_circumstances.revenues
      .filter((revenue) => revenue.amount !== 0);

    if (this.isConservator() && !this.isIndependentConservator()) {
      return this.conservatorAddFormSheet(this.value);
    }

    return this.addFormSheet(this.value);
  }
}
